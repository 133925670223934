import React from "react"
import { Link } from "gatsby"
import { Button, SectionTitle } from "@hemalr/react-blocks"
import styled from "styled-components"
import { primaryColor, secondaryColor } from "../../site-config"
import Layout from "../components/layout"
import StyledMain from "../components/styled-main"
import SEO from "../components/seo"
import HomeLogos from "../components/home-logos"

const StyledLi = styled.li`
  margin-bottom: 0.5rem;
`

const StyledP = styled.p`
  color: ${primaryColor};
  font-weight: normal;
  font-size: 1.25rem;
`

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: 2rem 0;
`

const StyledPriceHeading = styled.h2`
  text-align: center;
  margin: 0;
  color: ${secondaryColor};
  font-size: 1.5rem;
  padding: 0 1rem;
`

const StyledPricingColumnContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: minmax(300px, 404px) minmax(300px, 404px);
  justify-items: center;
  justify-content: center;
  @media (max-width: 650px) {
    & {
      grid-template-columns: auto;
      justify-items: center;
    }
  }
`

const StyledPricingColumn = styled.div`
  display: grid;
  justify-items: center;
  justify-content: stretch;
  background-color: #f5f5f5;
  padding: 1rem 0;
  color: ${secondaryColor};
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  & > * {
    margin: 0;
  }
  & > p {
    padding: 0 2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  & > ul {
    list-style-type: none;
    padding: 0 2rem;
  }
`

const StyledPrice = styled.div`
  text-align: center;
  width: 100%;
  background: ${secondaryColor};
  color: white;
  padding: 1rem 0;
  font-size: 2rem;
`

const PriceFeature = styled.li`
  margin: 1rem 0;
`

const AboutUs = () => (
  <Layout>
    <SEO title="Pricing" href="https://mopodiatry.com.au/pricing" />
    <SectionTitle text="Pricing" color={primaryColor} level="h1" size="3rem" />
    <StyledMain>
      <p style={{ marginBottom: "2rem" }}>
        <i>
          Note - Please refer to the FAQ&apos;s below for info on health
          insurance and Medicare bulk billing
        </i>
      </p>
      <StyledPricingColumnContainer>
        <StyledPricingColumn>
          <StyledPriceHeading>Initial Consultation</StyledPriceHeading>
          <p>Your First Visit</p>
          <StyledPrice>$95</StyledPrice>
          <ul>
            <PriceFeature>Examination & Diagnosis</PriceFeature>
            <PriceFeature>
              Includes Most Treatments (see FAQ's below)
            </PriceFeature>
          </ul>
        </StyledPricingColumn>
        <StyledPricingColumn>
          <StyledPriceHeading>Subsequent Consultations</StyledPriceHeading>
          <p>All follow up visits</p>
          <StyledPrice>$85</StyledPrice>
          <ul>
            <PriceFeature>Treatment Continuation (if required)</PriceFeature>
            <PriceFeature>
              Includes Most Treatments (see FAQ's below)
            </PriceFeature>
          </ul>
        </StyledPricingColumn>
      </StyledPricingColumnContainer>
      <ButtonContainer>
        <Link to="/book-appointment">
          <Button
            text="Yup, sort out my feet now!"
            size="1.5rem"
            color={primaryColor}
          />
        </Link>
      </ButtonContainer>
      <SectionTitle text="FAQ's" color={primaryColor} level="h1" size="3rem" />
      <StyledP>What treatment is included?</StyledP>
      <ul>
        <StyledLi>Callus removal</StyledLi>
        <StyledLi>Corn removal</StyledLi>
        <StyledLi>Reduction of over-thick toenails</StyledLi>
        <StyledLi>Fungal nail infections</StyledLi>
        <StyledLi>
          And... this list goes on and on... it's much easier to ask:
        </StyledLi>
      </ul>
      <StyledP>What treatment is NOT included in the price?</StyledP>
      <ul>
        <StyledLi>Ingrown toenail surgery</StyledLi>
        <StyledLi>
          Dispensed devices. For example orthotics, toe splints etc.
        </StyledLi>
      </ul>
      <StyledP>How can my visit be bulk billed to Medicare?</StyledP>
      <ul>
        <StyledLi>
          You must have a valid care plan from your GP, commonly called an EPC
          (enhanced primary care plan) or CDMP (chronic disease management
          plan).
        </StyledLi>
        <StyledLi>
          Your GP will assess whether you are eligible for the care plan.
          Diabetes is a chronic disease recognised by the care plan.
        </StyledLi>
        <StyledLi>
          Bring the care plan and medicare card with you for your appointment.
        </StyledLi>
        <StyledLi>
          Gold Card Veterans are also bulk-billed upon presenting their card.
        </StyledLi>
      </ul>
      <StyledP>Do you support my private health insurance?</StyledP>
      <ul>
        <StyledLi>
          We use HICAPS and almost all major health insurance providers allow us
          to process claims on the spot.
        </StyledLi>
        <StyledLi>
          This means you do not need to go through the hassle of claiming with
          them after the appointment and only pay for the portion of the visit
          not covered by them (if they do not cover the whole fee).
        </StyledLi>
      </ul>
      <StyledP>
        How much of the visit cost will my private health insurance cover?
      </StyledP>
      <ul>
        <StyledLi>
          This varies depending on your health fund and level of cover, it's
          best to check with them directly.
        </StyledLi>
        <StyledLi>
          Normally we see $20-$59 rebated straight away with our Hicaps
          facility.
        </StyledLi>
      </ul>
    </StyledMain>

    <HomeLogos />

    <ButtonContainer>
      <Link
        to="/book-appointment"
        style={{ margin: "auto", textAlign: "center" }}
      >
        <Button text="Book me in now" size="1.5rem" color={primaryColor} />
      </Link>
    </ButtonContainer>
  </Layout>
)

export default AboutUs
