import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const StyledDiv = styled.div`
  margin: 0 2rem;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 1rem;
  align-items: center;
  justify-items: center;
  @media (min-width: 650px) and (max-width: 1100px) {
    & {
      grid-template-columns: auto auto;
      grid-gap: 2rem;
    }
  }
  @media (max-width: 650px) {
    & {
      grid-template-columns: auto;
      grid-gap: 2rem;
    }
  }
`

const HomeLogos = () => {
  const data = useStaticQuery(graphql`
    fragment homeLogosImages on File {
      childImageSharp {
        fixed(height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    query {
      medicare: file(relativePath: { eq: "medicare-bulk-billing-logo.jpg" }) {
        ...homeLogosImages
      }
      bupa: file(relativePath: { eq: "bupa-logo.png" }) {
        ...homeLogosImages
      }
      hbf: file(relativePath: { eq: "hbf-logo.png" }) {
        childImageSharp {
          fixed(height: 64) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      medibank: file(relativePath: { eq: "medibank-logo.png" }) {
        childImageSharp {
          fixed(height: 94) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      hicaps: file(relativePath: { eq: "hicaps-logo.png" }) {
        childImageSharp {
          fixed(height: 29) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <StyledDiv>
      <Img
        fixed={data.bupa.childImageSharp.fixed}
        alt="BUPA preferred provider"
      />

      <Img
        fixed={data.medicare.childImageSharp.fixed}
        alt="Medicare bulk billing with care plan referrals"
      />

      <Img fixed={data.hbf.childImageSharp.fixed} alt="HBF direct claiming" />

      <Img
        fixed={data.medibank.childImageSharp.fixed}
        alt="Medibank direct claiming"
      />

      <Img
        fixed={data.hicaps.childImageSharp.fixed}
        alt="HICAPS direct claiming"
      />

      {/* <ImgContainer>
        <Img
          fixed={data.bupa.childImageSharp.fixed}
          alt="BUPA preferred provider"
        />
      </ImgContainer>
      <ImgContainer>
        <Img
          fixed={data.medicare.childImageSharp.fixed}
          alt="Medicare bulk billing with care plan referrals"
        />
      </ImgContainer>
      <ImgContainer>
        <Img fixed={data.hbf.childImageSharp.fixed} alt="HBF direct claiming" />
      </ImgContainer>

      <ImgContainer>
        <Img
          fixed={data.medibank.childImageSharp.fixed}
          alt="Medibank direct claiming"
        />
      </ImgContainer>
      <ImgContainer>
        <Img
          fixed={data.hicaps.childImageSharp.fixed}
          alt="HICAPS direct claiming"
        />
      </ImgContainer> */}
    </StyledDiv>
  )
}

export default HomeLogos

/*


*/
